import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Work from "./components/Work";
import Home from "./components/Home";
import WebApplication from "./components/Services/WebApplication";
import WebDevelopment from "./components/Services/WebDevelopment.js";
import HtmlEmails from "./components/Services/HtmlEmails.js";
import ScrollToTop from "./ScrollToTop";
import Aos from "aos";
import "aos/dist/aos.css";
import PageNotFound from "./components/PageNotFound";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 0,
    });
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/web-applications">
            <WebApplication />
          </Route>
          <Route path="/websites">
            <WebDevelopment />
          </Route>
          <Route path="/html-emails">
            <HtmlEmails />
          </Route>
          <Route path="/work">
            <Work />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
