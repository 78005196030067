import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import SectionHero from "../SectionHero";
import { Helmet } from "react-helmet";

import creative from "../../images/creative-home.png";
import Technologies from "./Technologies";
import Services from "./Services";
import Clients from "./Clients";
import Clientspeak from "./Clients_speak";
import PortFolioSection from "./Portfolio";
import Connect from "./Connect";
import GoCeres from "../../images/portfolio/project-go-ceres.png";
import Ittisa from "../../images/portfolio/project-ittisa.png";
import AdityaBirla from "../../images/portfolio/project-aditya-birla.png";

function Home() {
  const headings = {
    part_1: "Bring Your ",
    part_2: "Designs Alive",
  };

  const buttons = [
    {
      className: "button-shadow-blue",
      url: "/contact",
      text: "Let’s get started",
      type: "",
    },
    {
      className: "button-shadow-white",
      url: "#services",
      text: "See our services",
      type: "hash-link",
    },
  ];

  const Background = {
    color: "#FCFCFC",
    image: "",
    position: "",
    repeat: "",
  };

  const portFolioHeadings = {
    part_1: "Our Portfolio",
    part_2: "",
  };

  const cardData = [
    {
      name: "Go Ceres",
      about:
        "From design and development (F&B) to deployment. We build this document-sharing web application from scratch using the MERN stack.",
      imgSrc: GoCeres,
      imgOrder: 1,
      contentOrder: 2,
      alt: "go_ceres",
      cardNo: 1,
      url: "https://goceres.com/",
    },
    {
      name: "Ittisa",
      about:
        "We helped Ittisa, a digital marketing agency, in coding their design into reality.",
      imgSrc: Ittisa,
      imgOrder: 2,
      contentOrder: 1,
      alt: "ittisa",
      cardNo: 2,
      url: "https://ittisa.com/",
    },
    {
      name: "Aditya Birla Wealth Management System",
      about: "We helped an agency build its client's user interface.",
      imgSrc: AdityaBirla,
      imgOrder: 1,
      contentOrder: 2,
      alt: "aditya_birla",
      cardNo: 3,
      url: "https://wealth.adityabirlacapital.com/digital-gold/",
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Frontendone: A Front-end Development Company</title>
        <meta
          name="description"
          content="We are a front-end development company helping digital agencies, businesses, and professionals code website designs."
        />
      </Helmet>
      <Navbar />
      <SectionHero
        headings={headings}
        content="We are a front-end development company helping digital agencies and businesses get their designs running on the web. High coding standards, on-time delivery, and long-term support are our USPs."
        buttons={buttons}
        image={creative}
        background={Background}
      />
      <Technologies />
      <Services />
      <Clients />
      <PortFolioSection
        headings={portFolioHeadings}
        cards={cardData}
        showMore={true}
      />
      <Clientspeak />
      <Connect />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
