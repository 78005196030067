import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Standards({ standards }) {
  const standardList = [];
  let delay = 150;

  standards.forEach((standard) => {
    standardList.push(
      <li data-aos="fade-up" data-aos-delay={(delay += 50)}>
        <div>
          <img src={standard.icon} alt="Reusable Components" />
        </div>
        <div>
          <h3 className="h3-medium-24">{standard.heading}</h3>
          <p className="para-regular-16">{standard.paragraph}</p>
        </div>
      </li>
    );
  });

  return (
    <section className="standards">
      <Container>
        <Row>
          <Col>
            <h2
              data-aos="fade-up"
              data-aos-delay="100"
              className="h2-semibold-48"
            >
              Standard’s we adhere to
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>{standardList}</ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Standards;
