import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Clients() {
  return (
    <section className="section section-clients">
      <Container>
        <Row>
          <Col>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Who We Work With
            </h2>
          </Col>
        </Row>
        <Row data-aos="fade-up" data-aos-delay="0" className="client-list">
          <Col lg={{ span: 3, offset: 2 }}>
            <span>Startups</span>
          </Col>
          <Col lg={3}>
            <span>Digital Agencies</span>
          </Col>
          <Col lg={3}>
            <span>Digital Studios</span>
          </Col>
        </Row>
        <Row data-aos="fade-up" data-aos-delay="0" className="client-list">
          <Col lg={{ span: 3, offset: 2 }}>
            <span>Creative Studio</span>
          </Col>
          <Col lg={3}>
            <span>Businesses</span>
          </Col>
          <Col lg={3}>
            <span>Professionals</span>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Clients;
