import React, { useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as FileUploadIcon } from "../../images/contact/upload.svg";
import { ReactComponent as ErrorIcon } from "../../images/error.svg";
import { ReactComponent as SuccessIcon } from "../../images/contact/successIcon.svg";
import SectionCTA from "../SectionCTA/index.js";
import undrawContact from "../../images/contact/undraw_contact_us.svg";
import firebase from "../../firebase";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const handleValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name should be greater than 2 characters")
    .max(50, "Name should be less than 50 characters")
    .required("Please enter your name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter an email"),
  services: Yup.array().min(1, "Please select at least one of the service"),
  instruction: Yup.string()
    .min(5, "Please give more details about your work")
    .max(500, "Please keep the instructions below 500 characters")
    .required("Please describe the nature of the work you need"),
});

// reduces the character of the returned string
function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

//configuring toast
toast.configure();

function Contact() {
  //Ref for file input.
  const fileInput = useRef(null);

  const reCaptchaRef = useRef();

  //returns true when google captcha is validated successfully
  const [validCaptcha, setValidCaptcha] = useState(false);

  const [captchaValue, setCaptchaValue] = useState("");

  //returns true when file is selected.
  let isFileSelected = false;

  //array to store file size.
  const newArr = [];

  //variable to store total file size
  let totalFileSize = 0;

  const CTAheading = {
    part1: "Have some confusion about your project?",
    part2: "",
  };

  const CTAcontent = {
    part1: `Please email us at info@frontendone.com, and we will figure out the best solution.`,
    part2: "",
  };

  //converts the file to base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => {
        reader.abort();
        reject(new DOMException("Problem while parsing file."));
      };
    });

  //Message after submitting the form.
  function SubmitMessage(props) {
    if (props.type === "success") {
      return (
        <Row className="submitPopup">
          <div className="successIcon">
            <SuccessIcon />
          </div>
          <div>
            <p>
              <strong>Thank you for getting in touch!</strong>
            </p>
            <p>
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!
            </p>
            <p>Have a great day!</p>
          </div>
        </Row>
      );
    } else if (props.type === "error") {
      if (props.message) {
        return (
          <div>
            <p>{props.message}</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>Error while submitting form!</p>
          </div>
        );
      }
    }
  }

  //to open file explorer using UseRef
  function onBtnClick() {
    fileInput.current.click();
  }

  //handles saving data to firebase after submit.
  const handleSubmit = async (values, onSubmitProps) => {
    await new Promise((r) => setTimeout(r, 500));

    const captchaValue = reCaptchaRef.current.getValue();

    if (!captchaValue || captchaValue === "") {
      alert("Please validate captcha");
      return;
    }

    let parsedFiles = [];
    if (values.files.length > 0) {
      for (var i = 0; i < values.files.length; i++)
        parsedFiles.push({ href: await toBase64(values.files[i]) });
    }

    await firebase
      .firestore()
      .collection("mails_from_frontendone_website")
      .add({
        to: "info@frontendone.com",
        replyTo: `${values.email}`,
        message: {
          subject: `Message from Frontendone Website: ${values.name}`,
          html: `<b>${values.name}</b>
                        has sent you a message:<br/><br/>

                        They require <b>${Array.from(values.services).join(
                          ", "
                        )}</b> as service <br/><br/>

                        Message by ${values.name}: ${
            values.instruction
          }<br/><br/>

                        Attachments attached
                    `,
          attachments: parsedFiles,
        },
        name: `${values.name}`,
        services: `${Array.from(values.services).join(", ")}`,
        instructions: `${values.instruction}`,
      })
      .then((e) => {
        onSubmitProps.resetForm();
        newArr.splice(0, newArr.length);
        totalFileSize = 0;
        // toast.success(<SubmitMessage type="success" message="" />, {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 8000,
        // });
        alert("Message sent successfully");
        isFileSelected = false;
      })
      .catch((error) => {
        // toast.error(<SubmitMessage type="error" message={error} />, {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 5000,
        // });

        alert(
          "An unknown error occured, please send your message at info@frontendone.com"
        );
      });
  };

  function checkData(event) {
    //calculating the totalFileSize of the uploaded files.
    for (let i = 0; i < event.target.files.length; i++) {
      totalFileSize += event.target.files[i].size;
    }

    //pushing the files into an array if total file size is less than 1mb.
    if (totalFileSize / 1024 < 1024) {
      for (let i = 0; i < event.target.files.length; i++) {
        newArr.push(event.target.files[i]);
      }
      return true;
    } else {
      //subtracting size from totalfilesize after deleting the file.
      for (let i = 0; i < event.target.files.length; i++) {
        totalFileSize -= event.target.files[i].size;
      }
      return false;
    }
  }

  function onCaptchaUpdate(value) {
    if (value !== "") {
      setValidCaptcha(true);
      setCaptchaValue(value);
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Contact us using the form or mail us directly at info@frontendone.com. You can also call us at +91-9619483758 from Monday-Saturday, 10 AM to 6 PM."
        />
      </Helmet>
      <Navbar />

      <section className="contact-hero">
        <Container>
          <Row>
            <Col>
              <h1
                className="h1-semibold-64"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                Say Hello!
              </h1>
              <p
                className="h4-regular-18"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                Drop us a message with your requirements and get your
                <br className="break" />
                designs alive on the web faster.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-form">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  services: [],
                  instruction: "",
                  files: [],
                  fileError: "",
                }}
                validationSchema={handleValidation}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form data-aos="fade-up" data-aos-delay="0">
                    <Row>
                      <Col className="input-container input-resp">
                        <label>Name</label>
                        <Field
                          name="name"
                          placeholder="Name"
                          className="input-control name"
                          type="text"
                        />
                        <span>
                          <ErrorMessage
                            name="name"
                            render={(msg) => (
                              <div className="para-regular-12 errorMargin">
                                <ErrorIcon className="errorIcon" />
                                {msg}
                              </div>
                            )}
                          />
                        </span>
                      </Col>
                      <Col className="input-container input-resp">
                        <label>Email</label>
                        <Field
                          name="email"
                          placeholder="Email"
                          type="email"
                          className="input-control email"
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <div className="para-regular-12 errorMargin">
                              <ErrorIcon className="errorIcon" />
                              {msg}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <label>Services Needed</label>
                    <Row>
                      <Col md={4} className="servicesResp">
                        <div className="checkbox">
                          <Field
                            type="checkbox"
                            name="services"
                            value="Web Application"
                            id="web_application"
                          />
                          <label
                            className="para-regular-16"
                            htmlFor="web_application"
                          >
                            Web Application
                          </label>
                        </div>
                      </Col>

                      <Col md={4} className="servicesResp">
                        <div className="checkbox">
                          <Field
                            type="checkbox"
                            name="services"
                            value="Website"
                            id="website"
                          />
                          <label className="para-regular-16" htmlFor="website">
                            Website
                          </label>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="checkbox">
                          <Field
                            type="checkbox"
                            name="services"
                            value="HTML Email"
                            id="html_email"
                          />
                          <label
                            className="para-regular-16"
                            htmlFor="html_email"
                          >
                            HTML Email
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="input-container">
                        <ErrorMessage
                          name="services"
                          render={(msg) => (
                            <div className="para-regular-12 errorMargin">
                              <ErrorIcon className="errorIcon" />
                              {msg}
                            </div>
                          )}
                        ></ErrorMessage>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="input-container input-resp">
                        <label>Instructions</label>
                        <Field
                          as="textarea"
                          name="instruction"
                          className="textarea"
                          placeholder="Describe your requirements in detail"
                        />
                        <ErrorMessage
                          name="instruction"
                          render={(msg) => (
                            <div className="para-regular-12">
                              <ErrorIcon className="errorIcon" />
                              {msg}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="input-container input-resp">
                        <label>Upload Sample Design (optional)</label>
                        {!isFileSelected ? (
                          <div
                            className="custom-file-input"
                            onClick={onBtnClick}
                          >
                            <div className="custom-file-content">
                              <FileUploadIcon />
                              {/* <p className="para-regular-16">Upload</p>
                                                                <p className="para-regular-16">Select or drag and drop multiple files</p> */}
                            </div>
                            <input
                              type="file"
                              id="fileUpload"
                              name="files"
                              title=""
                              ref={fileInput}
                              multiple
                              accept="image/*,.pdf"
                              onChange={(e) => {
                                if (values.fileError) {
                                  setFieldValue("fileError", "");
                                }

                                let fileData = checkData(e);

                                if (fileData) {
                                  setFieldValue("files", newArr);
                                  isFileSelected = true;
                                } else {
                                  setFieldValue(
                                    "fileError",
                                    "File size should be less than 1mb."
                                  );
                                  isFileSelected = false;
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div className="custom-file-input cursor-type">
                            <div className="fileList">
                              <label className="para-regular-14">
                                Uploaded files
                              </label>
                              <FieldArray
                                name="files"
                                render={(arrayHelpers) => (
                                  <ul>
                                    {values.files.map((file, index) => (
                                      <li title={file.name} key={index}>
                                        <span className="textWidth">
                                          {truncateString(file.name, 14)}
                                        </span>
                                        <span className="progressBar"></span>
                                        <span
                                          className="removeFile"
                                          onClick={() => (
                                            setFieldValue(
                                              "files",
                                              values.files.filter(function (
                                                value
                                              ) {
                                                return value !== file;
                                              })
                                            ),
                                            newArr.splice(index, 1),
                                            (totalFileSize -= file.size),
                                            newArr.length === 0
                                              ? (isFileSelected = false)
                                              : isFileSelected
                                          )}
                                        ></span>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              />
                              <div className="fileIconLink">
                                <FileUploadIcon
                                  className="icon-cursor"
                                  title="Add files"
                                  onClick={onBtnClick}
                                />
                              </div>
                              <input
                                type="file"
                                id="fileData"
                                ref={fileInput}
                                multiple
                                accept="image/*,.pdf"
                                onChange={(e) => {
                                  if (values.fileError) {
                                    setFieldValue("fileError", "");
                                  }

                                  let fileData = checkData(e);

                                  if (fileData) {
                                    setFieldValue("files", newArr);
                                    isFileSelected = true;
                                  } else {
                                    setFieldValue(
                                      "fileError",
                                      "File size should be less than 1mb."
                                    );

                                    newArr.length > 0
                                      ? (isFileSelected = true)
                                      : (isFileSelected = false);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {values.fileError ? (
                          <label className="para-regular-12 errorMargin">
                            <ErrorIcon className="errorIcon" />
                            {values.fileError}
                          </label>
                        ) : (
                          values.fileError
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col className="input-container input-resp">
                        <ReCAPTCHA
                          sitekey="6LejTYYpAAAAAO64gCwmHKc7fWxcVCPOLD9zluAY"
                          // onChange={onChange}
                          ref={reCaptchaRef}
                        />
                      </Col>
                    </Row>

                    <div className="buttonResp">
                      <button type="submit" className="button" id="submit_form">
                        Send
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
      <SectionCTA
        heading={CTAheading}
        content={CTAcontent}
        image={undrawContact}
        button="false"
      />
      <Footer />
    </React.Fragment>
  );
}

export default Contact;
