import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../Navbar";
import Footer from "../Footer";
import SectionHero from "../SectionHero";
import Standards from "./Standards";

import creative from "../../images/services/html-emails-banner.svg";
import wellStructuredCode from "../../images/services/well-structured-code.svg";
import responsive from "../../images/services/Responsive.svg";
import rigorousTesting from "../../images/services/rigorous-testing.svg";
import markupData from "../../images/services/Markup.svg";
import integration from "../../images/services/integration.svg";

import htmlEmailCapture from "../../images/services/undraw-html-email-capture.svg";
import SectionCTA from "../SectionCTA/index.js";

function HtmlEmails() {
  const headings = {
    part1: "HTML ",
    part2: "Emails",
  };

  const content =
    "Be it Outlook, Gmail, or any other platform, we code HTML emails that work for most clients. Thorough testing and following strict guidelines prevent your emails from being marked as spam.";

  const standards = [
    {
      icon: wellStructuredCode,
      heading: "Well Structured Code",
      paragraph: "Proper nesting of tables makes the code more readable",
    },
    {
      icon: responsive,
      heading: "Responsive",
      paragraph:
        "Guarnteed responsiveness irrespective of the device and client",
    },
    {
      icon: rigorousTesting,
      heading: "Rigorous Testing",
      paragraph:
        "Testing funnel for responsiveness, compatibility and pixel perfection",
    },
    {
      icon: markupData,
      heading: "Markup Data",
      paragraph:
        "Markups to take advantage of extra features provided by specific email clients",
    },
    {
      icon: integration,
      heading: "Integration",
      paragraph:
        "Seamless integration with your marketing tools like MailChimp, Litmus, etc",
    },
  ];

  const Background = {
    color: "#F2F7FF",
    image: `${creative}`,
    position: "right bottom",
    repeat: "no-repeat",
    name: "HtmlEmails",
  };

  const CTAheading = {
    part1: "Broken Emails?",
    part2: "",
  };

  const CTAcontent = {
    part1: "Drop us your query and we will help you out.",
    part2:
      " Download a sample code and have a glance at the standards we follow..",
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Services: HTML Emails</title>
        <meta
          name="description"
          content="We code HTML Emails that most email clients support and offer integration services with your email marketing tools."
        />
      </Helmet>
      <Navbar />

      <SectionHero
        className="htmlemail"
        headings={headings}
        content={content}
        backgroundImage={creative}
        background={Background}
      />

      <Standards standards={standards} />
      <SectionCTA
        heading={CTAheading}
        content={CTAcontent}
        image={htmlEmailCapture}
      />
      <Footer />
    </React.Fragment>
  );
}

export default HtmlEmails;
