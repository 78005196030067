import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function SectionCTA({ heading, content, image }) {
  const headingList = [];
  const contentList = [];

  Object.entries(heading).map(([key, value]) => {
    return headingList.push(value);
  });


  Object.entries(content).map(([key, value]) => {
    return contentList.push(value);
  });

  return (
    <section className="section section-connect section-cta">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <h2 data-aos="fade-up" className=" section-heading h2-semibold-48">
              {headingList[0]}
              <br className="break" />
              {headingList[1]}
            </h2>
            <p data-aos="fade-up" className="para-regular-16">
              {contentList[0]}
            </p>
            {/* <p className="para-regular-16">
                            <Link to="">Download</Link> a sample code and have a glance at the standards we follow.
                        </p> */}
            {/* <a href="mailto:info@frontendone.com" className="button-footer">Let’s get started</a> */}
            <Link
              to="/contact"
              className="button-footer"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              Let’s get started
            </Link>
          </Col>
          <Col xs={12} lg={6}>
            <img src={image} alt="" className="responsive-img center-img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SectionCTA;
