import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Media from "react-media";

import { ReactComponent as Logo } from "../../images/frontendone-logo.svg";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

function Navbar() {
  const handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector("nav").className = "scroll";
    } else {
      document.querySelector("nav").className = "";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  function scrollToTop() {
    window.scroll(0, 0);
  }

  return (
    <nav>
      <Container>
        <Row>
          <Col xs={8} md={4}>
            <Link to="/" onClick={scrollToTop} className="logo">
              <Logo />
            </Link>
          </Col>
          <Col xs={4} md={8}>
            <Media queries={{ medium: { minWidth: 992 } }}>
              {(matches) => (matches.medium ? <DesktopMenu /> : <MobileMenu />)}
            </Media>
          </Col>
        </Row>
      </Container>
    </nav>
  );
}

export default Navbar;
