import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as WebApp } from "../../images/home/services/web-application.svg";
import WebAppBanner from "../../images/home/services/web-application-banner.svg";
import { ReactComponent as Website } from "../../images/home/services/websites.svg";
import WebsiteBanner from "../../images/home/services/websites-banner.svg";
import { ReactComponent as HtmlEmail } from "../../images/home/services/html-emails.svg";

import HtmlEmailBanner from "../../images/home/services/html-emails-banner.svg";
import { ReactComponent as RightArrow } from "../../images/right-arrow.svg";

function Services() {
  return (
    <section className="section section-services" id="services">
      <Container>
        <Row>
          <Col>
            <h1
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Our Services
            </h1>
          </Col>
        </Row>
        <Row data-aos="fade-up" data-aos-delay="0">
          <Col lg={4}>
            <div
              className="service-container"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <span>
                <WebApp />
              </span>
              <h3 className="h3-medium-24">Web Applications</h3>
              <p className="para-regular-16">React, Express, Mongo.</p>
              <Link to="/web-applications">
                Read More <RightArrow />
              </Link>
            </div>
          </Col>

          <Col lg={4}>
            <div
              className="service-container"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <span>
                <Website />
              </span>
              <h3 className="h3-medium-24">Websites</h3>
              <p className="para-regular-16">
                HTML, CSS, Javascript, Wordpress.
              </p>
              <Link to="/websites">
                Read More <RightArrow />
              </Link>
            </div>
          </Col>

          <Col lg={4}>
            <div
              className="service-container"
              data-aos="fade-up"
              data-aos-delay=" 0"
            >
              <span>
                <HtmlEmail />
              </span>
              <h3 className="h3-medium-24">HTML Emails</h3>
              <p className="para-regular-16">
                Responsive, Dark Mode, Integration
              </p>
              <Link to="/websites">
                Read More <RightArrow />
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="section service-detail">
          <Col lg={6}>
            <img
              src={WebAppBanner}
              alt="Web Application"
              className="responsive-img"
            />
          </Col>
          <Col lg={6}>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Web Applications
            </h2>
            <p data-aos="fade-up" className="para-regular-16">
              We build web apps with high performance and scalability in mind.
              Reusable components, strictly typed code, and tests for the
              overall app ensuring high security are the standards we adhere to
              using popular technologies like React, Express, and Mongo.
            </p>
            <Link data-aos="fade-up" data-aos-delay="0" to="/web-applications">
              Read More <RightArrow />
            </Link>
          </Col>
        </Row>

        <Row className="section service-detail">
          <Col xs={{ span: 12, order: "last" }} lg={{ span: 6, order: 1 }}>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Websites
            </h2>
            <p
              data-aos="fade-up"
              data-aos-delay="0"
              className="para-regular-16"
            >
              We add life to your designs, be it PSD, Sketch, XD, or Figma,
              using the latest HTML semantics and CSS properties covering almost
              all types of browsers installed on different operating systems.
            </p>
            <Link to="/websites" data-aos="fade-up" data-aos-delay="0">
              Read More <RightArrow />
            </Link>
          </Col>
          <Col xs={{ span: 12, order: "first" }} lg={{ span: 6, order: 12 }}>
            <img
              src={WebsiteBanner}
              alt="Websites"
              className="responsive-img"
            />
          </Col>
        </Row>

        <Row className="section service-detail">
          <Col lg={6}>
            <img
              src={HtmlEmailBanner}
              alt="Web Application"
              className="responsive-img"
            />
          </Col>
          <Col lg={6}>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              HTML Emails
            </h2>
            <p
              data-aos="fade-up"
              data-aos-delay="0"
              className="para-regular-16"
            >
              We relieve the pain of coding and testing a perfect responsive
              HTML email that works on most email clients, integrates with your
              email marketing tool, and supports the latest features like dark
              mode and metadata integration.
            </p>
            <Link to="/html-emails" data-aos="fade-up" data-aos-delay="0">
              Read More <RightArrow />
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
