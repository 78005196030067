import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Dropdown from "../UI/dropdown";
import { ClickOutsideRef } from "../../Utils";

function DesktopMenu() {
  return (
    <ul className="menu-desktop">
      <li>
        <NavLink
          activeClassName="active"
          to="/about"
          onClick={window.scroll(0, 0)}
        >
          About
        </NavLink>
      </li>
      <li>
        <Dropdown />
      </li>
      <li>
        <NavLink activeClassName="active" to="/work">
          Our Work
        </NavLink>
      </li>
      <li>
        <a
          activeClassName="active"
          href="https://medium.com/@frontendone"
          target="_blank"
        >
          Blog
        </a>
      </li>
      <li>
        <NavLink to="/contact" className="button-nav">
          Let’s talk
        </NavLink>
      </li>
    </ul>
  );
}

export default DesktopMenu;
