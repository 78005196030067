import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../Navbar";
import Footer from "../Footer";
import SectionHero from "../SectionHero";
import Standards from "./Standards";

import creative from "../../images/hero-banner-webapplication.png";
import reuscableCompIcon from "../../images/services/reusable-components-icon.svg";
import wellDocumentedCodeIcon from "../../images/services/well-documented-code-icon.svg";
import minimalRenderingIcon from "../../images/services/minimal-rendering-icon.svg";
import fileSystemIcon from "../../images/services/file-system-icon.svg";
import trustedPackagesIcon from "../../images/services/trusted-packages-icon.svg";

import framework from "../../images/services/frameworks.svg";
import SectionCTA from "../SectionCTA/index.js";

function WebApplication() {
  const headings = {
    part1: "Web ",
    part2: "Applications",
  };

  const content =
    "We specialize in developing web applications using frameworks and libraries like React, Express, Node, and MongoDB, which help you quickly build your app and gear it for the web with performance and scalability.";

  const standards = [
    {
      icon: reuscableCompIcon,
      heading: "Reusable Components",
      paragraph:
        "Merging multiple components with similar features for better code readability",
    },
    {
      icon: wellDocumentedCodeIcon,
      heading: "Well Documented Code",
      paragraph:
        "Detailed documentation of each feature in a separate doc so that you know what each line of the code does",
    },
    {
      icon: minimalRenderingIcon,
      heading: "Minimal Rendering",
      paragraph:
        "Removing unnecessary renders at each stage of the component lifecycle helps boost performance",
    },
    {
      icon: fileSystemIcon,
      heading: "Structured File System",
      paragraph:
        "Highly structured arrangement of files making the app understandable even by the novice eye",
    },
    {
      icon: trustedPackagesIcon,
      heading: "Trusted and Supported Packages",
      paragraph:
        "Packages that are trusted and well supported by package managers like npm and yarn",
    },
  ];

  const Background = {
    color: "#F2F7FF",
    image: "",
    position: "right bottom",
    repeat: "no-repeat",
  };

  const CTAheading = {
    part1: "React / Angular / Vue?",
    part2: "",
  };

  const CTAcontent = {
    part1:
      "Confused about which framework to choose for your revolutionary web app? No Worries, we are here. Drop us a message with your requirements and we will help you out with the best framework to start with.",
    part2: "",
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Services: Web App Development</title>
        <meta
          name="descritption"
          content="We use JavaScript tech stacks like React and Node to develop web applications that are well-documented, rigorously tested, and have a structured file system."
        />
      </Helmet>
      <Navbar />

      <SectionHero
        headings={headings}
        content={content}
        image={creative}
        background={Background}
      />

      <Standards standards={standards} />
      <SectionCTA heading={CTAheading} content={CTAcontent} image={framework} />
      <Footer />
    </React.Fragment>
  );
}

export default WebApplication;
