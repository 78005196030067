// displays banner and it's contents on first screen
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Media from "react-media";

function SectionHero({
  headings,
  content,
  buttons,
  image,
  page,
  background,
  className,
}) {
  const buttonList = [];
  const headingList = [];

  Object.entries(headings).map(([key, value]) => {
    return headingList.push(value);
  });

  if (buttons) {
    buttons.forEach((button) => {
      buttonList.push(
        <li>
          {button.type == "hash-link" ? (
            <HashLink to={button.url} className={button.className}>
              {button.text}
            </HashLink>
          ) : (
            <a className={button.className} href={button.url}>
              {button.text}
            </a>
          )}
        </li>
      );
    });
  }
  return (
    <section
      className={`section-hero ${className}`}
      style={{
        backgroundColor: background.color,
        backgroundImage: `url(${background.image})`,
        backgroundPosition: background.position,
        backgroundRepeat: background.repeat,
        backgroundSize: background.size,
      }}
    >
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col md={12} lg={6}>
            {page !== "About" ? (
              <h1
                data-aos="fade-up"
                data-aos-delay="0"
                className="h1-semibold-64"
              >
                {headingList[0]}
                <br className="break" />
                {headingList[1]}
              </h1>
            ) : (
              <h1
                data-aos="fade-up"
                data-aos-delay="0"
                className="h2-semibold-48"
              >
                {headingList[0]}
                <br className="break" />
                {headingList[1]}
              </h1>
            )}

            <p
              data-aos="fade-up"
              data-aos-delay="100"
              className="h4-regular-18"
            >
              {content}
            </p>
            <ul data-aos="fade-up" data-aos-delay="200">
              {buttonList}
            </ul>
          </Col>
          <Col md={12} lg={6}>
            {image ? (
              <img
                src={image}
                className="responsive-img"
                alt="Frontendone Creatives"
              />
            ) : (
              <Media queries={{ medium: { minWidth: 992 } }}>
                {(matches) =>
                  matches.medium ? (
                    ""
                  ) : (
                    <img
                      src={background.image}
                      alt=""
                      className="responsive-img"
                    />
                  )
                }
              </Media>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SectionHero;
