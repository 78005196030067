import firebase from "firebase/app";

var firebaseConfig = {
    apiKey: "AIzaSyCza6-OK-hGN8e6D2YwBeLnQhPr5IzNdK0",
    authDomain: "mauryan-technologies.firebaseapp.com",
    projectId: "mauryan-technologies",
    storageBucket: "mauryan-technologies.appspot.com",
    messagingSenderId: "174757690210",
    appId: "1:174757690210:web:95b4b9e98995fd08160a3b",
    measurementId: "G-4RC73VP0S5"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

export default firebase;