import { useEffect } from 'react';

// function to close dialog boxes when clicked outside of them
export function ClickOutsideRef(ref, action) {
    const handelClick = (e) => {
        if (ref.current.contains(e.target)) {
            return;
        }
        action();
    }

    useEffect(() => {
        document.addEventListener("mousedown", handelClick);
        return () => {
            document.removeEventListener("mousedown", handelClick);
        }
    }, [ref]);
}

export function emailValid(val) {
    if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/.test(val)) {
        return true
    } else {
        return false
    }
}

export function nameValid(val) {
    if (/^[a-zA-Z ]+$/.test(val)) {
        return true
    } else {
        return false
    }
}