import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Creative from "../../images/404-creative.png";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <>
      <Helmet>
        {/* <title>404: Page Not Found</title>
        <meta name="robots" content="none" /> */}
      </Helmet>
      <Navbar />
      <section className="section pnf-content">
        <Container>
          <Row>
            <Col>
              <img src={Creative} className="responsive-img pnf-creative" />
              <p className="h4-regular-18">
                Oops! It seems that the page you are looking for does not exist
              </p>
              <ul>
                <li>
                  <a className="button-shadow-white" href="/">
                    Go Home
                  </a>
                </li>
                <li>
                  <a className="button-shadow-white" href="/contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default PageNotFound;
