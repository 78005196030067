import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { ReactComponent as HTML } from "../../images/technologies/html.svg";
import { ReactComponent as CSS } from "../../images/technologies/css.svg";
import { ReactComponent as JavaScript } from "../../images/technologies/javascript.svg";
import { ReactComponent as SASS } from "../../images/technologies/sass.svg";
import { ReactComponent as ReactJS } from "../../images/technologies/react.svg";
import { ReactComponent as Angular } from "../../images/technologies/angular.svg";
import { ReactComponent as Vue } from "../../images/technologies/vue.svg";
import { ReactComponent as WordPress } from "../../images/technologies/wordpress.svg";
import { ReactComponent as AWS } from "../../images/technologies/aws.svg";
import { ReactComponent as MongoDB } from "../../images/technologies/mongodb.svg";
import { ReactComponent as Firebase } from "../../images/technologies/firebase.svg";
import { ReactComponent as NodeJS } from "../../images/technologies/nodejs.svg";
import { ReactComponent as Shopify } from "../../images/technologies/shopify.svg";

function Technologies() {
  return (
    <section className="section section-technologies">
      <Container>
        <Row>
          <Col>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Technologies We Use
            </h2>

            <ul data-aos="fade-up" data-aos-delay="0">
              <li>
                <HTML />
                <p>HTML</p>
              </li>
              <li>
                <CSS />
                <p>CSS</p>
              </li>
              <li>
                <JavaScript />
                <p>Javascript</p>
              </li>
              <li>
                <SASS />
                <p>Sass</p>
              </li>
              <li>
                <ReactJS />
                <p>React</p>
              </li>
              {/* <li>
                <Angular />
                <p>Angular</p>
              </li>
              <li>
                <Vue />
                <p>Vue</p>
              </li> */}
              <li>
                <WordPress />
                <p>Wordpress</p>
              </li>
              <li>
                <Shopify />
                <p>Shopify</p>
              </li>
              <li>
                <AWS />
                <p>AWS</p>
              </li>
              <li>
                <MongoDB />
                <p>MongoDB</p>
              </li>
              <li>
                <Firebase />
                <p>Firebase</p>
              </li>
              <li>
                <NodeJS />
                <p>NodeJS</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Technologies;
