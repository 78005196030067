import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

const Cards = (props) => {
  return (
    <Row className="portfolio-cards">
      <Col lg={{ span: 7, order: props.imgOrder }} className="nopad">
        <img
          src={props.imgSrc}
          alt={props.alt}
          className={`${props.cardNo % 2 === 0 ? "evencard" : "oddcard"}`}
        />
      </Col>
      <Col lg={{ span: 5, order: props.contentOrder }} className="content">
        <div>
          <h2
            data-aos="fade-up"
            data-aos-delay="0"
            className="section-heading h2-semibold-48"
          >
            {props.name}
          </h2>
          <p data-aos="fade-up">{props.about}</p>
          {/* <span data-aos="fade-up">Our Role: {props.role}</span> */}
          <Link
            data-aos="fade-up"
            data-aos-delay="0"
            to={{ pathname: `${props.url}` }}
            target="_blank"
          >
            <button className="button-shadow-blue">See Website</button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

const PortFolioSection = (props) => {
  const headingList = [];

  Object.entries(props.headings).map(([key, value]) => {
    return headingList.push(value);
  });

  return (
    <div className="section portfolio-section">
      <Container>
        <Row>
          <Col className="column">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="portfoliosecheading"
            >
              {headingList[0]}
              <br className="break" />
              {headingList[1]}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {props.cards.map((card) => {
              return (
                <Cards
                  name={card.name}
                  about={card.about}
                  imgSrc={card.imgSrc}
                  imgOrder={card.imgOrder}
                  contentOrder={card.contentOrder}
                  alt={card.alt}
                  url={card.url}
                />
              );
            })}

            {props.showMore ? (
              <div className="text-center">
                <a className="button-shadow-white" href="/work">
                  See More
                </a>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PortFolioSection;
