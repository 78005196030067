import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { ReactComponent as Twitter } from "../../images/Path.svg";
import { ReactComponent as LinkedIn } from "../../images/Group.svg";
import { ReactComponent as Message } from "../../images/Message.svg";
import { ReactComponent as Mobile } from "../../images/Mobile.svg";
import { ReactComponent as Location } from "../../images/Location.svg";
import { ReactComponent as Logo } from "../../images/frontendone-logo-footer.svg";

function Footer() {
  return (
    <footer>
      <Container>
        <Row className="main-footer">
          <Col
            xs={{ span: 12, order: "last" }}
            lg={{ span: 4, order: "first" }}
          >
            <div className="frontend-done">
              <Link to="/" className="footer-logo">
                <Logo />
              </Link>
              <p>
                A front-end development company helping digital agencies and
                businesses get their designs running on the web
              </p>
              <ul>
                <li>
                  <a href="https://twitter.com/getFrontendone" target="_blank">
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/frontendone"
                    target="_blank"
                  >
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={5} lg={4}>
            <div className="services">
              <h4 className="h4-medium-20">Services</h4>
              <ul>
                <li>
                  {" "}
                  <Link to="/web-applications">Web Applications</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/websites">Websites</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/html-emails">HTML Emails </Link>{" "}
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={7} lg={4}>
            <div className="reach-us">
              <h4>Reach us</h4>
              <ul>
                <li>
                  <a href="mailto:info@frontendone.com" id="raw_email">
                    <Message />
                    <span>info@frontendone.com</span>
                  </a>
                </li>
                <li>
                  {/* <Link to="/">  */}
                  <Location />
                  <span>Mumbai</span>
                  {/* </Link> */}
                </li>
                <li>
                  <Mobile />
                  <span>
                    <a
                      href="tel: +91-9619483758"
                      id="toll_free_number_frontendone"
                    >
                      +91-9619483758
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="copy">
            <hr />
            <p className="para-regular-14">
              &copy; {new Date().getFullYear()} Frontendone. All rights reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
