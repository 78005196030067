import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import SectionHero from "../SectionHero";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import creative from "../../images/about/undraw-experience-about-page.svg";
import { ReactComponent as WhoWeAreIcon } from "../../images/about/who-we-are.svg";
import { ReactComponent as OurValuesIcon } from "../../images/about/our-values-icon.svg";
import purpleCircle from "../../images/about/about-banner.svg";
import designTeam from "../../images/about/Need-successful-project-icon.svg";
import SectionCTA from "../SectionCTA/index.js";

function About() {
  const pageName = "About";

  const headings = {
    part1: "One of the fastest",
    part2: " growing companies",
    part3: "about",
  };

  const content =
    "Founded amid the COVID-19 pandemic in 2020, We bridge the gap between beautiful designs and robust backends and help you deliver services to your customers most efficiently across different domains.";

  const Background = {
    color: "#F2FDFF",
    image: `${purpleCircle}`,
    position: "right top",
    repeat: "no-repeat",
  };

  const CTAheading = {
    part1: "Need a successful project?",
    part2: "",
  };

  const CTAcontent = {
    part1: "",
    part2: "",
  };

  function scrollToTop() {
    window.scroll(0, 0);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="We bridge the gap between beautiful designs and robust backends by providing front-end development as a service. This helps our clients efficiently deliver to their customers across different domains."
        />
      </Helmet>
      <Navbar />

      <SectionHero
        headings={headings}
        content={content}
        backgroundImage={purpleCircle}
        image={creative}
        page={pageName}
        background={Background}
      />

      <section className="section section-services about">
        <Container>
          <Row className="about service-detail">
            <Col lg={6}>
              <span>
                <WhoWeAreIcon className="responsive-img" />
              </span>
            </Col>
            <Col lg={6}>
              <h2
                data-aos="fade-up"
                data-aos-delay="0"
                className="section-heading h2-semibold-48 about-headings "
              >
                What We Do
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="0"
                className="para-regular-16"
              >
                We specialize in coding great user interfaces for Web
                Applications, Websites, and HTML Emails, making the
                communication between you and your customers a pleasing
                experience.
              </p>
            </Col>
          </Row>

          <Row className="section service-detail about">
            <Col xs={{ span: 12, order: "last" }} lg={{ span: 6, order: 1 }}>
              <h2
                data-aos="fade-up"
                data-aos-delay="0"
                className="section-heading h2-semibold-48"
              >
                How We Help
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="0"
                className="para-regular-16 our-value"
              >
                We remove the burden of coding and testing a robust front end by
                collaborating with your already established team of coders and
                providing bug-free, well-documented, and rigorously tested code.
              </p>
            </Col>
            <Col xs={{ span: 12, order: "first" }} lg={{ span: 6, order: 12 }}>
              <span>
                <OurValuesIcon className="responsive-img " />{" "}
              </span>
            </Col>
          </Row>
        </Container>
      </section>
      <SectionCTA
        heading={CTAheading}
        content={CTAcontent}
        image={designTeam}
      />
      <Footer />
    </React.Fragment>
  );
}

export default About;
