import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../Navbar";
import Footer from "../Footer";
import SectionHero from "../SectionHero";
import Standards from "./Standards";

import creative from "../../images/services/web-development-banner.svg";
import latestHtmlSemantics from "../../images/services/latest-html-semantics.svg";
import wellDocumentedCodeIcon from "../../images/services/well-documented-code-icon.svg";
import svgCdnIcon from "../../images/services/svg-cdn-icon.svg";
import fileSystemIcon from "../../images/services/file-system-icon.svg";
import testingIcon from "../../images/services/testing-icon.svg";
import purpleCircle from "../../images/services/Purple-Circle.png";
import designTeam from "../../images/services/undraw-design-team.svg";
import SectionCTA from "../SectionCTA/index.js";

function WebDevelopment() {
  const headings = {
    part1: "Website ",
    part2: "Development",
  };

  const content =
    "We code designs to their pixel-perfect web version using HTML5, CSS/SASS, Bootstrap, and JQuery. Responsiveness, browser support, and performance are what we have in mind while coding websites.";

  const standards = [
    {
      icon: latestHtmlSemantics,
      heading: "Latest HTML Semantics",
      paragraph:
        "Latest and proper use of HTML semantics makes your website accessible on different platforms",
    },
    {
      icon: wellDocumentedCodeIcon,
      heading: "Well Documented Code",
      paragraph:
        "Detailed documentation of each feature in a separate doc so that you know what each line of the code does",
    },
    {
      icon: svgCdnIcon,
      heading: "SVGs and CDNs",
      paragraph:
        "Using SVGs for images and CDNs for plugins reducing overall load time",
    },
    {
      icon: fileSystemIcon,
      heading: "Structured File System",
      paragraph:
        "Highly structured arrangement of files making the app understandable even by the novice eye",
    },
    {
      icon: testingIcon,
      heading: "Testing",
      paragraph:
        "Testing website at component level so that it never goes out of its defined behavior across platforms",
    },
  ];

  const Background = {
    color: "#F2FDFF",
    image: `${purpleCircle}`,
    position: "right top",
    repeat: "no-repeat",
  };

  const CTAheading = {
    part1: "How Should I Start?",
    part2: "",
  };

  const CTAcontent = {
    part1:
      "Drop us a message with your sample design, and we will help you with the take-off of your website.",
    part2: "",
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Service: Website Development</title>
        <meta
          name="description"
          content="We code pixel-perfect web versions of your designs, keeping responsiveness, browser support, and performance in mind. We use the latest HTML5, CSS3, Bootstrap, and other web technologies."
        />
      </Helmet>
      <Navbar />

      <SectionHero
        headings={headings}
        content={content}
        backgroundImage={purpleCircle}
        image={creative}
        background={Background}
      />

      <Standards standards={standards} />
      <SectionCTA
        heading={CTAheading}
        content={CTAcontent}
        image={designTeam}
      />
      <Footer />
    </React.Fragment>
  );
}

export default WebDevelopment;
