import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as DropdownIcon } from '../../images/dropdown-icon.svg';
import { ClickOutsideRef } from '../../Utils';

function Dropdown() {

    const [stateDropdown, setStateDropdown] = useState(false);

    const dropdownRef = useRef(null);

    const dropdownArrowRef = useRef(null);

    
    

    const toggleDropdown = () => {
        setStateDropdown(!stateDropdown);
    }

    useEffect(() => {
        if (stateDropdown === true) {
            dropdownArrowRef.current.style.transform = 'rotate(180deg)';
        } else {
            dropdownArrowRef.current.style.transform = 'rotate(0deg)';
        }
    })

    ClickOutsideRef(dropdownRef, () => {setStateDropdown(false)});

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button onClick={toggleDropdown}>
                Services <DropdownIcon ref={dropdownArrowRef} />
            </button>
            {
                stateDropdown ?
                <div className="dropdown-menu">
                    <ul>
                        <li>
                            <Link to="/web-applications">Web Applications</Link>
                        </li>
                        <li>
                            <Link to="/websites">Websites</Link>
                        </li>
                        <li>
                            <Link to="/html-emails">HTML Emails</Link>
                        </li>
                    </ul>
                </div>
                :
                null
            }
        </div>
    )
}

export default Dropdown;