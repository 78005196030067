import React from "react";
import clientImg from "../../images/client_speak/user (1).png";

const ClientSpeakCard = (props) => {
  return (
    <>
      <section className="client_card scrollbar-hidden">
        <div>
          <div className="scrollbar-hidden">
            <h2 data-aos="fade-up" data-aos-delay="0" className="">
              {props.title}
            </h2>

            <p data-aos="fade-up">{props.about}</p>
          </div>
        </div>
      </section>
      <div class="triangle-down"></div>
      <section className="client_info">
        <img src={clientImg} alt="client_img" />
        <h3 data-aos="fade-up">{props.clientName}</h3>
        <p data-aos="fade-up">{props.designation}</p>
      </section>
    </>
  );
};

export default ClientSpeakCard;
