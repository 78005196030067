import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import PortFolioSection from "../Home/Portfolio";
import SectionHero from "../SectionHero";
import SectionCTA from "../SectionCTA";
import img1 from "../../images/portfolio/img1.png";
import img3 from "../../images/portfolio/img3.png";
import img2 from "../../images/portfolio/img2.png";
import GoCeres from "../../images/portfolio/project-go-ceres.png";
import Ittisa from "../../images/portfolio/project-ittisa.png";
import AdityaBirla from "../../images/portfolio/project-aditya-birla.png";
import TataTiscon from "../../images/portfolio/project-tata-tiscon.png";
import KulkarniLawFirm from "../../images/portfolio/project-kulkarni-law-firm.png";
import { Helmet } from "react-helmet";

function Work() {
  const headings = {
    part_1: "Our Work",
    part_2: "",
  };

  const Background = {
    color: "#FCFCFC",
    image: `${img3}`,
    position: "center bottom",
    repeat: "no-repeat",
    size: "100%",
  };

  const CTAheading = {
    part1: "Convinced?",
    part2: "",
  };

  const CTAcontent = {
    // part1:
    //   "Download a sample code for Web app. Website, HTML Email and have a glance at the standards we follow.",
    part1: "",
    part2: "",
  };

  const portFolioHeadings = {
    part_1: "Enough talking let's see",
    part_2: "what we have got",
  };

  const cardData = [
    {
      name: "Go Ceres",
      about:
        "From design and development (F&B) to deployment. We build this document-sharing web application from scratch using the MERN stack.",
      imgSrc: GoCeres,
      imgOrder: 1,
      contentOrder: 2,
      alt: "go_ceres",
      cardNo: 1,
      url: "https://goceres.com/",
    },
    {
      name: "Ittisa",
      about:
        "We helped Ittisa, a digital marketing agency, in coding their design into reality.",
      imgSrc: Ittisa,
      imgOrder: 2,
      contentOrder: 1,
      alt: "ittisa",
      cardNo: 2,
      url: "https://ittisa.com/",
    },
    {
      name: "Aditya Birla Wealth Management System",
      about: "We helped an agency build its client's user interface.",
      imgSrc: AdityaBirla,
      imgOrder: 1,
      contentOrder: 2,
      alt: "aditya_birla",
      cardNo: 3,
      url: "https://wealth.adityabirlacapital.com/digital-gold/",
    },
    {
      name: "Tata Tiscon",
      about: "We helped an agency build its client's user interface.",
      imgSrc: TataTiscon,
      imgOrder: 2,
      contentOrder: 1,
      alt: "tata_tiscon",
      cardNo: 4,
      url: "https://tatatiscon.co.in/",
    },
    {
      name: "Kulkarni Law Firm",
      about:
        "We designed and developed this WordPress website for an overseas client.",
      imgSrc: KulkarniLawFirm,
      imgOrder: 1,
      contentOrder: 2,
      alt: "kulkarni_law_firm",
      cardNo: 5,
      url: "https://kulkarnilawfirm.com/",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Our Work</title>
        <meta
          name="description"
          content="We have worked on projects for well-known brands like Aditya Birla, Tata, and other businesses and provided them with complete web solutions."
        />
      </Helmet>
      <Navbar />
      <div className="sectionhero_portfolio">
        <SectionHero
          headings={headings}
          content="A quick view of industry-specific problems solved by the incredible team at Frontedone."
          image={img1}
          background={Background}
          backgroundImage={img3}
        />
      </div>

      <PortFolioSection
        headings={portFolioHeadings}
        cards={cardData}
        showMore={false}
      />
      <SectionCTA heading={CTAheading} content={CTAcontent} image={img2} />
      <Footer />
    </>
  );
}

export default Work;
