import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import Dropdown from "../UI/dropdown";
import { ReactComponent as MenuIcon } from "../../images/menu-icon.svg";

function MobileMenu() {
  const [mobileMenu, toggleMobileMenu] = useState(false);

  const toggleMenuRef = useRef(null);

  return (
    <div ref={toggleMenuRef}>
      <span className="toggle-menu">
        <button onClick={() => toggleMobileMenu(!mobileMenu)}>
          <MenuIcon />
        </button>
      </span>

      {mobileMenu ? (
        <ul className="menu-mobile">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Dropdown />
          </li>

          <li>
            <Link to="/work">Our Work</Link>
          </li>
          <li>
            <a href="https://medium.com/@frontendone" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}

export default MobileMenu;
