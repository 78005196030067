import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClientSpeakCard from "./Clientspeak_card";

const ClientSpeakSlider = () => {
  const clientData = [
    {
      clientName: "Darshan Kulkarni",
      designation: "Regulatory and Compliance Attorney, Kulkarni Law Firm",
      title: "Knowledgeable Team",
      about:
        "Team Frontendone has worked on multiple websites and programs for my companies. They have been great and responsive. I love working with their knowledgeable team.",
    },
    {
      clientName: "Swati Nathani",
      designation: "Co-founder & CBO, Team Pumpkin",
      title: "Efficient Collaborating",
      about:
        "Team Frontendone coded our design for one of our clients. Not only are they quick in responding, but they also explain why and how they do everything, so you get a great website and an education on the process, which helps in handling clients. Team Frontendone is super easy to work with, and we will use their services in the future.",
    },

    {
      clientName: "Jay Sitlani",
      designation: " Founder & CEO, Crash Boom Bang Digital",
      title: "Intuitive Design",
      about:
        "The team at Frontendone optimized my website very well; I will approach them for my future projects.",
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    accessibility: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="sliderwidth">
      {clientData.map((el, i) => {
        return (
          <Col>
            <ClientSpeakCard
              title={el.title}
              about={el.about}
              clientName={el.clientName}
              designation={el.designation}
              key={i}
            />
          </Col>
        );
      })}
    </Slider>
  );
};

const clientSpeak = () => {
  return (
    <section className="section sectionback">
      <Container className="clientcont">
        <Row>
          <Col xs={12}>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="clientspeak_heading"
            >
              Our Clients Speak
            </h2>
          </Col>
        </Row>
        <Row>
          <ClientSpeakSlider />
        </Row>
      </Container>
    </section>
  );
};

export default clientSpeak;
