import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Media from "react-media";
import { Link } from "react-router-dom";

import connect from "../../images/home/connect.svg";

function Connect() {
  return (
    <section className="section section-connect">
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              className="section-heading h2-semibold-48"
            >
              Want To Work <br className="break" /> With Us?
            </h2>
            <p
              data-aos="fade-up"
              data-aos-delay="0"
              className="para-regular-16"
            >
              Expertise and Guidance, Trust and Support, Collaboration and
              Bonding are the values and skills we thrive on.
            </p>
            <Link
              to="/contact"
              className="button-footer"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-anchor-placement="bottom-bottom"
            >
              Let’s get started
            </Link>
          </Col>
          {/* <Col>
                        <Media queries={{ medium: { minWidth: 992 } }}>
                            {matches =>
                                matches.medium ? (
                                    ""
                                ) : (
                                    <img src={connect} alt="" className="responsive-img" />
                                )
                            }
                        </Media>
                    </Col> */}
        </Row>
      </Container>
    </section>
  );
}

export default Connect;
